import {Checkbox, TextField} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import irrImg from '../../../assets/auth-bg.svg';

export default function Login() {
  return (
    <div className='flex h-screen p-2 bg-house-pattern bg-tr-white justify-center bg-no-repeat bg-center bg-cover bg-blend-color-burn items-center'>
      <img draggable="false" className='fixed top-auto' width={450} src={irrImg} alt="bg" />
      <div className='shadow z-10 px-5 pt-10 pb-20 m-auto w-full max-w-xs bg-white text-center'>
        <h3 className='text-3xl font-bold text-austel-green'>Login</h3>
        <span className='text-austel-green text-sm block mb-2'>Welcome Back Austel</span>
        <CustomTextField label="Phone Number" />
        <CustomTextField label="Password" />
        <div className='flex text-xs text-gray-700 text-left mb-8 items-center'>
          <Checkbox style={{padding: '0'}} size='small' />
          <span className='ml-1'>Remember me</span>
        </div>
        <button className='text-white bg-austel-green py-2 px-20 my-2 block mx-auto text-sm rounded'><Link to="/tenant/dashboard">Login</Link></button>
        <span className="text-xs">Don't have an account? <Link className='text-austel-green' to="/tenant/signup">Register</Link></span>
      </div>
    </div>
  )
}

const CustomTextField = (props) => {
  const styles = {
    label: {
      fontSize: 13
    },
    inputP: {
      fontSize: 13,
      marginBottom: 10
    }
  }
  return <>
    <TextField
      variant='standard'
      end
      size='small'
      fullWidth
      InputLabelProps={{style: styles.label}}
      InputProps={{
        style: styles.inputP
      }}
      {...props}
    />
  </>
}
