import React from 'react'

const Header = () => {
    return (
        <div className='bg-white w-full px-12 border-b-2 justify-end flex'>
            <img src="/profile-avatar.png" className='rounded-full w-[70px] h-[70px] cursor-pointer' alt="" />
        </div>
    )
}

export default Header