import React, { useState } from 'react';
import DashboardNav from '../../components/DashboardNav';
import Header from '../../components/Header';

const Payment = () => {
    const [myInvoice, setMyInvoice] = useState(false);
    return (
        <div className='w-full h-full min-h-screen bg-white flex'>
            <DashboardNav />
            <div className='md:w-10/12 w-full min-h-screen max-h-screen flex flex-col relative overflow-y-auto'>
                <Header />
                {/* Pending payment */}
                <div className='p-4 lg:p-16 flex flex-col gap-4 w-full md:px-8 lg:max-w-7xl mx-auto lg:mb-0'>
                    <h3 className='text-[#0FA958] font-semibold'>Pending Payment</h3>
                    <div className='h-auto flex flex-col lg:flex-row gap-8 lg:gap-24 shadow px-8 py-10 rounded-md'>
                        <div className='flex flex-col gap-4'>
                            <div className='flex flex-col gap-2'>
                                <div className='flex gap-12 lg:gap-64 font-semibold text-[#6C6C6C] text-sm'>
                                    <p className='w-4/12'>Annual Rent</p>
                                    <p className='w-28'># 75,000</p>
                                </div>
                                <hr />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <div className='flex gap-12 lg:gap-64 font-semibold text-[#6C6C6C] text-sm'>
                                    <p className='w-4/12'>Agent Fee</p>
                                    <p className='w-28'># 5,000</p>
                                </div>
                                <hr />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <div className='flex gap-12 lg:gap-64 font-semibold text-[#6C6C6C] text-sm'>
                                    <p className='w-4/12'>Security Fee</p>
                                    <p className='w-28'># 5,000</p>
                                </div>
                                <hr />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <div className='flex gap-12 lg:gap-64 font-semibold text-[#6C6C6C] text-sm'>
                                    <p className='w-4/12'>PHCN Bill</p>
                                    <p className='w-28'># 15,000</p>
                                </div>
                                <hr />
                            </div>
                        </div>
                        <div className='text-[#6C6C6C] flex flex-col gap-1'>
                            <p className='text-sm'>Total</p>
                            <h2 className='font-bold text-xl'># 100,000</h2>
                            <button className='text-white bg-[#0FA958] px-10 py-1.5 text-sm'>Pay now</button>
                            <button className='text-white bg-[#BD2424] px-10 py-1.5 text-sm'>Pay part</button>
                        </div>
                    </div>
                    <div className='w-full px-8 py-3 md:py-5'>
                        <p className='text-[#6C6C6C] text-sm'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Velit ratione, enim ad esse ipsa non neque. Provident sunt pariatur dolores dolor esse, asperiores nisi perferendis recusandae aperiam assumenda placeat. Repellat.</p>
                        <button className='text-white bg-[#0FA958] px-10 py-1.5 text-sm mt-4' onClick={() => setMyInvoice(true)}>Print Invoice</button>
                    </div>
                </div>
                {/* Payment History */}
                {/* <div className='flex flex-col w-full p-4 md:px-8 lg:px-16 lg:max-w-7xl mx-auto mb-4 md:mb-8'>
                    <div className='h-auto flex flex-col gap-8 shadow rounded'>
                        <div className='flex justify-between items-center bg-[#EAFFF4] text-[#6C6C6C] px-4 py-3 w-full'>
                            <p className='text-[11px] md:text-sm'>July 27, 2022 | 2:02PM</p>
                            <p className='text-[11px] md:text-sm'>Txn ID: 1992346746721</p>
                            <p className='text-[11px] md:text-base font-semibold'># 100,000</p>
                        </div>
                        <div className='flex flex-col md:flex-row gap-5'>
                            <div className='w-full md:w-6/12 px-8 py-3 md:py-5 md:border-r border-[#6C6C6C]'>
                                <p className='text-[#6C6C6C] text-sm'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Velit ratione, enim ad esse ipsa non neque. Provident sunt pariatur dolores dolor esse, asperiores nisi perferendis recusandae aperiam assumenda placeat. Repellat.</p>
                                <button className='text-white bg-[#0FA958] px-10 py-1.5 text-sm mt-4'>Print Invoice</button>
                            </div>
                            <div className='w-full px-8 md:p-0 md:w-6/12 flex flex-col gap-4'>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex gap-12 lg:gap-44 font-semibold text-[#6C6C6C] text-sm'>
                                        <p className='w-4/12'>Annual Rent</p>
                                        <p className='w-28'># 75,000</p>
                                    </div>
                                    <hr />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex gap-12 lg:gap-44 font-semibold text-[#6C6C6C] text-sm'>
                                        <p className='w-4/12'>Agent Fee</p>
                                        <p className='w-28'># 5,000</p>
                                    </div>
                                    <hr />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex gap-12 lg:gap-44 font-semibold text-[#6C6C6C] text-sm'>
                                        <p className='w-4/12'>Security Fee</p>
                                        <p className='w-28'># 5,000</p>
                                    </div>
                                    <hr />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex gap-12 lg:gap-44 font-semibold text-[#6C6C6C] text-sm'>
                                        <p className='w-4/12'>PHCN Bill</p>
                                        <p className='w-28'># 15,000</p>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='flex flex-col w-full p-4 md:px-8 lg:px-16 lg:max-w-7xl mx-auto gap-3'>
                    <h3 className='text-[#0FA958] font-semibold'>Payment History</h3>
                    <div className='flex justify-between items-center bg-[#EAFFF4] text-[#6C6C6C] px-4 py-3 w-full shadow-md'>
                        <p className='text-[11px] md:text-sm'>July 27, 2022 | 2:02PM</p>
                        <p className='text-[11px] md:text-sm'>Txn ID: 1992346746721</p>
                        <p className='text-sm md:text-basefont-semibold'># 100,000</p>
                        <button className='bg-[#0FA958] px-10 py-1.5 text-sm text-white' onClick={() => setMyInvoice(true)}>Print</button>
                    </div>
                    <div className='flex justify-between items-center bg-[#EAFFF4] text-[#6C6C6C] px-4 py-3 w-full shadow-md'>
                        <p className='text-[11px] md:text-sm'>July 27, 2022 | 2:02PM</p>
                        <p className='text-[11px] md:text-sm'>Txn ID: 1992346746721</p>
                        <p className='text-sm md:text-basefont-semibold'># 100,000</p>
                        <button className='bg-[#0FA958] px-10 py-1.5 text-sm text-white' onClick={() => setMyInvoice(true)}>Print</button>
                    </div>
                    <div className='flex justify-between items-center bg-[#EAFFF4] text-[#6C6C6C] px-4 py-3 w-full shadow-md'>
                        <p className='text-[11px] md:text-sm'>July 27, 2022 | 2:02PM</p>
                        <p className='text-[11px] md:text-sm'>Txn ID: 1992346746721</p>
                        <p className='text-sm md:text-basefont-semibold'># 100,000</p>
                        <button className='bg-[#0FA958] px-10 py-1.5 text-sm text-white' onClick={() => setMyInvoice(true)}>Print</button>
                    </div>
                    <div className='flex justify-between items-center bg-[#EAFFF4] text-[#6C6C6C] px-4 py-3 w-full shadow-md'>
                        <p className='text-[11px] md:text-sm'>July 27, 2022 | 2:02PM</p>
                        <p className='text-[11px] md:text-sm'>Txn ID: 1992346746721</p>
                        <p className='text-sm md:text-basefont-semibold'># 100,000</p>
                        <button className='bg-[#0FA958] px-10 py-1.5 text-sm text-white' onClick={() => setMyInvoice(true)}>Print</button>
                    </div>
                    <div className='flex justify-between items-center bg-[#EAFFF4] text-[#6C6C6C] px-4 py-3 w-full shadow-md'>
                        <p className='text-[11px] md:text-sm'>July 27, 2022 | 2:02PM</p>
                        <p className='text-[11px] md:text-sm'>Txn ID: 1992346746721</p>
                        <p className='text-sm md:text-basefont-semibold'># 100,000</p>
                        <button className='bg-[#0FA958] px-10 py-1.5 text-sm text-white' onClick={() => setMyInvoice(true)}>Print</button>
                    </div>
                    <div className='flex justify-between items-center bg-[#EAFFF4] text-[#6C6C6C] px-4 py-3 w-full shadow-md'>
                        <p className='text-[11px] md:text-sm'>July 27, 2022 | 2:02PM</p>
                        <p className='text-[11px] md:text-sm'>Txn ID: 1992346746721</p>
                        <p className='text-sm md:text-basefont-semibold'># 100,000</p>
                        <button className='bg-[#0FA958] px-10 py-1.5 text-sm text-white' onClick={() => setMyInvoice(true)}>Print</button>
                    </div>
                    <div className='flex justify-between items-center bg-[#EAFFF4] text-[#6C6C6C] px-4 py-3 w-full shadow-md'>
                        <p className='text-[11px] md:text-sm'>July 27, 2022 | 2:02PM</p>
                        <p className='text-[11px] md:text-sm'>Txn ID: 1992346746721</p>
                        <p className='text-sm md:text-basefont-semibold'># 100,000</p>
                        <button className='bg-[#0FA958] px-10 py-1.5 text-sm text-white' onClick={() => setMyInvoice(true)}>Print</button>
                    </div>
                </div>
                {myInvoice && <div className="fixed top-0 right-0 bg-black/60 dark:bg-black/50 overflow-y-auto w-full h-screen flex z-40 overflow-hidden justify-center items-center" onClick={() => setMyInvoice(false)}>
                    <div className='bg-white w-3/6 px-16 py-14 rounded flex flex-col gap-8 overflow-y mt-[32rem] print:bg-white print:w-5/6 print:px-0 print:py-14 print:rounded print:flex print:flex-col print:gap-8 print:overflow-y print:mt-0'>
                        <div className='flex flex-col gap-6'>
                            <div className='flex flex-col gap-14'>
                                <div className='flex justify-between'>
                                    <h2 className='font-semibold text-[#1b1a1a] text-2xl w-44'>PathFinder Realtech LTD.</h2>
                                    <div className='flex flex-col gap-3 text-sm'>
                                        <p className='font-semibold'>For support and enquires:</p>
                                        <div className='flex flex-col gap-2'>
                                            <p>0700 2255 69684</p>
                                            <p>support@nti.edu.ng</p>
                                            <p>SMS: 09064579779</p>
                                            <p>Whatsapp: 09097807503</p>
                                            <p>Facebook.com/ntiedung</p>
                                            <p>Twitter.com/ntiedung</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-3'>
                                    <h2 className='text-3xl font-semibold'>Invoice</h2>
                                    <div className='flex flex-col gap-3 w-5/6'>
                                        <div className='flex justify-between text-sm text-left'>
                                            <label className='font-semibold'>Bill to:</label>
                                            <div className='flex flex-col gap-1'>
                                                <p>Felix A.</p>
                                                <p>NTI/PGDE/2022/2944</p>
                                                <p>felix@gmail.com</p>
                                                <p>08064150420</p>
                                            </div>
                                        </div>
                                        <div className='flex justify-between text-sm text-left'>
                                            <label className='font-semibold'>Order ID:</label>
                                            <div className='flex flex-col'>
                                                <p>270522120355170816S839415</p>
                                            </div>
                                        </div>
                                        <div className='flex justify-between text-sm text-left'>
                                            <label className='font-semibold'>Payment RRR:</label>
                                            <div className='flex flex-col'>
                                                <p>1706-6201-0645</p>
                                            </div>
                                        </div>
                                        <div className='flex justify-between text-sm text-left'>
                                            <label className='font-semibold'>Invoice Date:</label>
                                            <div className='flex flex-col'>
                                                <p>Fri, May 27, 2022 12:03 PM</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col gap-10 py-7 border-b-2 border-[#0FA958]'>
                                <div className='flex justify-between border-b border-t border-gray-400 px-8 py-4 font-semibold'>
                                    <h2>Description</h2>
                                    <h2>Amount</h2>
                                </div>
                                <div className='flex flex-col gap-4 px-6 print:px-0'>
                                    <div className='flex flex-col gap-2'>
                                        <div className='flex gap-12 lg:gap-64 font-semibold text-[#6C6C6C] text-sm'>
                                            <p className='w-4/12'>Annual Rent</p>
                                            <p className='w-28'># 75,000</p>
                                        </div>
                                        <hr />
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                        <div className='flex gap-12 lg:gap-64 font-semibold text-[#6C6C6C] text-sm'>
                                            <p className='w-4/12'>Agent Fee</p>
                                            <p className='w-28'># 5,000</p>
                                        </div>
                                        <hr />
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                        <div className='flex gap-12 lg:gap-64 font-semibold text-[#6C6C6C] text-sm'>
                                            <p className='w-4/12'>Security Fee</p>
                                            <p className='w-28'># 5,000</p>
                                        </div>
                                        <hr />
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                        <div className='flex gap-12 lg:gap-64 font-semibold text-[#6C6C6C] text-sm'>
                                            <p className='w-4/12'>PHCN Bill</p>
                                            <p className='w-28'># 15,000</p>
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='ml-64 flex justify-between w-62 text-sm font-semibold'>
                            <p>TOTAL</p>
                            <p># 100,000.00</p>
                        </div>
                        <div className='flex flex-col items-end justify-end gap-5 print:hidden'>
                            <button className='text-white w-32 bg-[#0FA958] px-5 py-2 text-sm'>Print</button>
                            <button className='text-white w-32 bg-[#BD2424] px-5 py-2 text-sm'>Pay Now</button>
                        </div>
                    </div>
                </div>}
            </div>
        </div >
    )
}

export default Payment