import { MenuItem, TextField } from '@mui/material';
import React, { useState } from 'react';
import DashboardNav from '../../components/DashboardNav';
import Header from '../../components/Header';

const Complaints = () => {
  const [showForm, setShowForm] = useState(false);
  // const category = [
  //   {
  //     value: 'Category',
  //   },
  //   {
  //     value: 'Plumbing',
  //   },
  //   {
  //     value: 'Electricity',
  //   },
  //   {
  //     value: 'Water Supply',
  //   },
  //   {
  //     value: 'Security',
  //   },
  // ];
  // const [currency, setCurrency] = useState('EUR');

  // const handleChange = (event) => {
  //   setCurrency(event.target.value);
  // };
  return (
    <div className='w-full h-full min-h-screen bg-white flex'>
      <DashboardNav />
      <div className='md:w-10/12 w-full min-h-screen max-h-screen flex flex-col relative overflow-y-auto'>
        <Header />
        <div className='mt-5 p-4 md:px-8 lg:px-16 flex flex-col gap-12'>
          <div>
            <h2 className='text-[#0FA958] font-semibold'>Lodge a complaint</h2>
            <div className='flex justify-between'>
              <div>
                <p className='text-[#C4C4C4] text-sm w-[569px]'>We are very concerned about your convenience , please don’t hesiatate to lodge any complaint you may have about your apartment.</p>
              </div>
              <button className='bg-[#0FA958] w-[178px] h-[40px] px-4 text-sm text-white' onClick={() => setShowForm(true)}>Lodge Complaint</button>
            </div>
            {showForm && <div className="fixed top-0 right-0 bg-black/60 dark:bg-black/50 w-full h-screen flex z-40 overflow-hidden justify-center items-center">
              <div className='bg-white w-[950px] mx-auto h-[380px] rounded flex'>
                <div className='w-[565px] p-14 flex flex-col gap-10'>
                  <div className='flex flex-col gap-3'>
                    <h2 className='text-[#0FA958] font-semibold'>Complaints form</h2>
                    <p className='text-[#6C6C6C] text-sm'>Please, kindly fill the form below and  we’ll get back back to in no time.</p>
                  </div>
                  <form action="">
                    <div className='flex flex-col gap-8'>
                      <TextField id="outlined-basic" label="Subject" variant="outlined" />
                      <TextField
                        id="outlined-multiline-static"
                        label="Message"
                        Your message here
                        // rows={4}
                        style={{ width: '100%', marginBottom: '10px', height: '10px' }}
                      />
                    </div>
                  </form>
                </div>
                <div className='w-[390px] bg-[#0FA958]'>
                  <div className='flex justify-end p-6 cursor-pointer' onClick={() => setShowForm(false)}>
                    <img src="/cancel.png" alt="" />
                  </div>
                  {/* <form action="" className='p-14'>
                    <TextField
                      id="standard-select-currency"
                      select
                      // label="Select"
                      style={{ color: 'white' }}
                      value={currency}
                      onChange={handleChange}
                      variant="standard"
                    >
                      {category.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </form> */}
                </div>
              </div>
            </div>}
          </div>
          <div className='flex flex-col w-full lg:max-w-7xl mx-auto gap-6'>
            <h3 className='text-[#0FA958] font-semibold'>Previous Complaints</h3>
            <div className='shadow w-[896px] flex flex-col gap-2'>
              <div className='flex justify-between items-center bg-[#EAFFF4] text-[#6C6C6C] px-4 py-3 w-full'>
                <p className='text-[11px] md:text-lg text-[#0FA958] font-semibold'>Plumbing</p>
                <p className='text-[11px] md:text-sm'>ID:19923</p>
                <p className='text-sm md:text-sm'>Last updated: Aug 21,2022</p>
                <p className='text-[11px] md:text-sm text-[#0C9A00] font-semibold'>Resolved</p>
              </div>
              <div className='flex flex-col gap-3 px-6 '>
                <div className='flex justify-between'>
                  <h2 className='text-base text-[#6C6C6C] font-medium'>Subject</h2>
                  <p className='text-[#C4C4C4] text-sm mt-3'>Created Aug 21, 2022.</p>
                </div>
                <p className='text-[#6C6C6C] text-sm pb-5 w-[640px]'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint quidem, deleniti vel porro perspiciatis architecto ut. Sed asperiores reiciendis facere eaque voluptates iusto cumque distinctio, veniam, explicabo soluta quia obcaecati?</p>
              </div>
            </div>
            <div className='shadow w-[896px] flex flex-col gap-2'>
              <div className='flex justify-between items-center bg-[#EAFFF4] text-[#6C6C6C] px-4 py-3 w-full'>
                <p className='text-[11px] md:text-lg text-[#0FA958] font-semibold'>Plumbing</p>
                <p className='text-[11px] md:text-sm'>ID:19923</p>
                <p className='text-sm md:text-sm'>Last updated: Aug 21,2022</p>
                <p className='text-[11px] md:text-sm text-[#FF9900] font-semibold'>Pending</p>
              </div>
              <div className='flex flex-col gap-3 px-6 '>
                <div className='flex justify-between'>
                  <h2 className='text-base text-[#6C6C6C] font-medium'>Subject</h2>
                  <p className='text-[#C4C4C4] text-sm mt-3'>Created Aug 21, 2022.</p>
                </div>
                <div className='flex justify-between'>
                  <p className='text-[#6C6C6C] text-sm pb-5 w-[640px]'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint quidem, deleniti vel porro perspiciatis architecto ut. Sed asperiores reiciendis facere eaque voluptates iusto cumque distinctio, veniam, explicabo soluta quia obcaecati?</p>
                  <button className='text-white bg-[#FF9900] text-sm w-[178px] h-[30px] mt-2'>Send a reminder</button>
                </div>
              </div>
            </div>
            <div className='shadow w-[896px] flex flex-col gap-2'>
              <div className='flex justify-between items-center bg-[#EAFFF4] text-[#6C6C6C] px-4 py-3 w-full'>
                <p className='text-[11px] md:text-lg text-[#0FA958] font-semibold'>Plumbing</p>
                <p className='text-[11px] md:text-sm'>ID:19923</p>
                <p className='text-sm md:text-sm'>Last updated: Aug 21,2022</p>
                <p className='text-[11px] md:text-sm text-[#FF9900] font-semibold'>Pending</p>
              </div>
              <div className='flex flex-col gap-3 px-6 '>
                <div className='flex justify-between'>
                  <h2 className='text-base text-[#6C6C6C] font-medium'>Subject</h2>
                  <p className='text-[#C4C4C4] text-sm mt-3'>Created Aug 21, 2022.</p>
                </div>
                <div className='flex justify-between'>
                  <p className='text-[#6C6C6C] text-sm pb-5 w-[640px]'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint quidem, deleniti vel porro perspiciatis architecto ut. Sed asperiores reiciendis facere eaque voluptates iusto cumque distinctio, veniam, explicabo soluta quia obcaecati?</p>
                  <button className='text-white bg-[#FF9900] text-sm w-[178px] h-[30px] mt-2'>Send a reminder</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Complaints