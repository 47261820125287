import React from 'react'
import DashboardNav from '../../components/DashboardNav'
import Header from '../../components/Header'

const Inbox = () => {
    return (
        <div className='w-full h-full min-h-screen bg-white flex'>
            <DashboardNav />
            <div className='md:w-10/12 w-full min-h-screen max-h-screen flex flex-col relative overflow-y-auto'>
                <Header />
                <div className='mt-5 p-4 md:px-8 lg:px-16 flex flex-col gap-6'>
                    <div className='flex gap-2 border border-[#0FA958] h-[38px] w-[360px] items-center p-3 rounded'>
                        <img src="/search.png" className='w-[16px] h-[16px]' alt="" />
                        <input className='p-3 bg-transparent focus:outline-none text-sm text-[#6C6C6C]' placeholder='Search inbox' />
                    </div>
                    <div className='flex flex-col w-full lg:max-w-7xl mx-auto gap-4'>
                        <div className='shadow w-[896px]'>
                            <div className='flex justify-between items-center bg-[#EAFFF4] text-[#6C6C6C] px-4 py-3 w-full'>
                                <div className='flex gap-3'>
                                    <img src="/avatar-2.png" className='w-[63px] h-[63px]' alt="" />
                                    <div>
                                        <p className='text-[11px] md:text-sm text-[#0FA958] font-semibold'>Adewole M</p>
                                        <small className='text-[#6C6C6C] text-[12px]'>Agent</small>
                                    </div>
                                </div>
                                <p className='text-[11px] md:text-sm'>Aug 21, 2022</p>
                                <p className='text-sm md:text-basefont-semibold flex gap-2'>
                                    2
                                    <img src="/clip.png" className='w-[16px] h-[8px] items-center mt-1.5' alt="" />
                                </p>
                                <div className='flex gap-5'>
                                    <img src="/share.png" className='w-[16px] h-[16px]' alt="" />
                                    <img src="/delete.png" className='w-[16px] h-[16px]' alt="" />
                                    <img src="/download.png" className='w-[16px] h-[16px]' alt="" />
                                </div>
                            </div>
                            <p className='text-[#6C6C6C] px-6 text-sm pb-5'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint quidem, deleniti vel porro perspiciatis architecto ut. Sed asperiores reiciendis facere eaque voluptates iusto cumque distinctio, veniam, explicabo soluta quia obcaecati?</p>
                        </div>
                        <div className='shadow w-[896px]'>
                            <div className='flex justify-between items-center bg-[#EAFFF4] text-[#6C6C6C] px-4 py-3 w-full'>
                                <div className='flex gap-3'>
                                    <img src="/avatar-2.png" className='w-[63px] h-[63px]' alt="" />
                                    <div>
                                        <p className='text-[11px] md:text-sm text-[#0FA958] font-semibold'>Adewole M</p>
                                        <small className='text-[#6C6C6C] text-[12px]'>Agent</small>
                                    </div>
                                </div>
                                <p className='text-[11px] md:text-sm'>Aug 21, 2022</p>
                                <p className='text-sm md:text-basefont-semibold flex gap-2'>
                                    2
                                    <img src="/clip.png" className='w-[16px] h-[8px] items-center mt-1.5' alt="" />
                                </p>
                                <div className='flex gap-5'>
                                    <img src="/share.png" className='w-[16px] h-[16px]' alt="" />
                                    <img src="/delete.png" className='w-[16px] h-[16px]' alt="" />
                                    <img src="/download.png" className='w-[16px] h-[16px]' alt="" />
                                </div>
                            </div>
                            <p className='text-[#6C6C6C] px-6 text-sm pb-5'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint quidem, deleniti vel porro perspiciatis architecto ut. Sed asperiores reiciendis facere eaque voluptates iusto cumque distinctio, veniam, explicabo soluta quia obcaecati?</p>
                        </div>
                        <div className='shadow w-[896px]'>
                            <div className='flex justify-between items-center bg-[#EAFFF4] text-[#6C6C6C] px-4 py-3 w-full'>
                                <div className='flex gap-3'>
                                    <img src="/avatar-2.png" className='w-[63px] h-[63px]' alt="" />
                                    <div>
                                        <p className='text-[11px] md:text-sm text-[#0FA958] font-semibold'>Adewole M</p>
                                        <small className='text-[#6C6C6C] text-[12px]'>Agent</small>
                                    </div>
                                </div>
                                <p className='text-[11px] md:text-sm'>Aug 21, 2022</p>
                                <p className='text-sm md:text-basefont-semibold flex gap-2'>
                                    2
                                    <img src="/clip.png" className='w-[16px] h-[8px] items-center mt-1.5' alt="" />
                                </p>
                                <div className='flex gap-5'>
                                    <img src="/share.png" className='w-[16px] h-[16px]' alt="" />
                                    <img src="/delete.png" className='w-[16px] h-[16px]' alt="" />
                                    <img src="/download.png" className='w-[16px] h-[16px]' alt="" />
                                </div>
                            </div>
                            <p className='text-[#6C6C6C] px-6 text-sm pb-5'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint quidem, deleniti vel porro perspiciatis architecto ut. Sed asperiores reiciendis facere eaque voluptates iusto cumque distinctio, veniam, explicabo soluta quia obcaecati?</p>
                        </div>
                        <div className='shadow w-[896px]'>
                            <div className='flex justify-between items-center bg-[#EAFFF4] text-[#6C6C6C] px-4 py-3 w-full'>
                                <div className='flex gap-3'>
                                    <img src="/avatar-2.png" className='w-[63px] h-[63px]' alt="" />
                                    <div>
                                        <p className='text-[11px] md:text-sm text-[#0FA958] font-semibold'>Adewole M</p>
                                        <small className='text-[#6C6C6C] text-[12px]'>Agent</small>
                                    </div>
                                </div>
                                <p className='text-[11px] md:text-sm'>Aug 21, 2022</p>
                                <p className='text-sm md:text-basefont-semibold flex gap-2'>
                                    2
                                    <img src="/clip.png" className='w-[16px] h-[8px] items-center mt-1.5' alt="" />
                                </p>
                                <div className='flex gap-5'>
                                    <img src="/share.png" className='w-[16px] h-[16px]' alt="" />
                                    <img src="/delete.png" className='w-[16px] h-[16px]' alt="" />
                                    <img src="/download.png" className='w-[16px] h-[16px]' alt="" />
                                </div>
                            </div>
                            <p className='text-[#6C6C6C] px-6 text-sm pb-5'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint quidem, deleniti vel porro perspiciatis architecto ut. Sed asperiores reiciendis facere eaque voluptates iusto cumque distinctio, veniam, explicabo soluta quia obcaecati?</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inbox