import React from 'react';
// import {FaDyalog, FaFileInvoiceDollar} from 'react-icons/fa';
// import {HiMail} from 'react-icons/hi';
// import {IoIosPeople} from 'react-icons/io';
// import {MdHome, MdMessage, MdPayment} from 'react-icons/md';
import {Link, useLocation} from 'react-router-dom';
// import { AiFillSetting } from 'react-icons/ai';
// import {FiEdit2} from 'react-icons/fi';

const DashboardNav = () => {
    const pathname = useLocation().pathname;
    return (
        <>
            <div className='md:w-3/12 xl:w-[20%] bg-[#EAFFF4] h-screen hidden md:flex flex-col shadow md:py-10 lg:py-7 md:gap-4 lg:gap-8 overflow-y-auto overflow-x-hidden'>
                <div className='mx-auto flex flex-col gap-4 px-9 '>
                    <Link to="/tenant/edit-profile" className='relative cursor-pointer w-[120px] h-[120px]'>
                        <img src="/profile-avatar.png" className='rounded-full' alt="" />
                        <div className='bg-green-400 rounded-full text-white w-6 h-6 flex items-center justify-center absolute top-24 left-[5.75rem] cursor-pointer'>
                            {/* <FiEdit2 /> */}
                        </div>
                    </Link>
                    <div className='text-[#6C6C6C]'>
                        <p className='font-semibold'>Funke Olarewaju</p>
                        <small className='text-[12px]'>Tenancy ID:2022D25</small>
                    </div>
                </div>
                <ul className='flex flex-col md:gap-0 lg:gap-0 w-full'>
                    <li className='flex px-1'>
                        <span className={`${pathname === '/tenant/dashboard' ? 'w-1 h-16  bg-[#0FA958]' : 'hidden'}`}></span>
                        <Link to="/tenant/dashboard" className={`${pathname === '/tenant/dashboard' ? 'bg-[#F1F1F1] flex items-center gap-3 w-full px-6 py-5' : 'flex items-center gap-3 w-full px-6 py-5'}`}>
                            {/* <MdHome className='text-[#6C6C6C]' size="3vh" /> */}
                            <p className='text-[#6C6C6C] font-medium text-[14px]'>Dashboard</p>
                        </Link>
                    </li>
                    <li className='flex px-1'>
                        <span className={`${pathname === '/tenant/payment' ? 'w-1 h-16  bg-[#0FA958]' : 'hidden'}`}></span>
                        <Link to="/tenant/payment" className={`${pathname === '/tenant/payment' ? 'bg-[#F1F1F1] flex items-center gap-3 w-full px-6 py-5' : 'flex items-center gap-3 w-full px-6 py-5'}`}>
                            {/* <MdPayment className='text-[#6C6C6C]' size="3vh" /> */}
                            <p className='text-[#6C6C6C] font-medium text-[14px]'>Payment</p>
                        </Link>
                    </li>
                    <li className='flex px-1'>
                        <span className={`${pathname === '/tenant/inbox' ? 'w-1 h-16  bg-[#0FA958]' : 'hidden'}`}></span>
                        <Link to="/tenant/inbox" className={`${pathname === '/tenant/inbox' ? 'bg-[#F1F1F1] flex items-center gap-3 w-full px-6 py-5' : 'flex items-center gap-3 w-full px-6 py-5'}`}>
                            {/* <HiMail className='text-[#6C6C6C]' size="3vh" /> */}
                            <p className='text-[#6C6C6C] font-medium text-[14px]'>Inbox</p>
                        </Link>
                    </li>
                    <li className='flex px-1'>
                        <span className={`${pathname === '/tenant/complaints' ? 'w-1 h-16  bg-[#0FA958]' : 'hidden'}`}></span>
                        <Link to="/tenant/complaints" className={`${pathname === '/tenant/complaints' ? 'bg-[#F1F1F1] flex items-center gap-3 w-full px-6 py-5' : 'flex items-center gap-3 w-full px-6 py-5'}`}>
                            {/* <MdPayment className='text-[#6C6C6C]' size="3vh" /> */}
                            <p className='text-[#6C6C6C] font-medium text-[14px]'>Complaints</p>
                        </Link>
                    </li>
                    <li className='flex px-1'>
                        <span className={`${pathname === '/tenant/notice' ? 'w-1 h-16  bg-[#0FA958]' : 'hidden'}`}></span>
                        <Link to="/tenant/notice" className={`${pathname === '/tenant/notice' ? 'bg-[#F1F1F1] flex items-center gap-3 w-full px-6 py-5' : 'flex items-center gap-3 w-full px-6 py-5'}`}>
                            {/* <FaFileInvoiceDollar className='text-[#6C6C6C]' size="3vh" /> */}
                            <p className='text-[#6C6C6C] font-medium text-[14px]'>Notice of leaving</p>
                        </Link>
                    </li>
                    <li className='flex px-1'>
                        <Link to="/" className='flex items-center gap-3 p-6'>
                            {/* <FaDyalog className='text-[#6C6C6C]' size="3vh" /> */}
                            <p className='text-[#6C6C6C] font-medium text-[14px]'>Log Out</p>
                        </Link>
                    </li>
                </ul>
                {/* <div className='flex flex-col'>
                    <div>
                        <p className='text-[#6C6C6C] text-[14px]'>Contact us</p>
                        <Link to="/" className='text-[#0FA958] md:text-[14px] lg:text-[13px] font-semibold'>0810-000-0000</Link>
                        <Link to="/" className='text-[#0FA958] md:text-[14px] lg:text-[13px] font-semibold'>email@austel.com</Link>
                    </div>
                    <div className='text-[#6C6C6C] flex flex-col'>
                        <Link to="/" className='md:text-[14px] lg:text-[12px]'>Terms and Conditions</Link>
                        <Link to="/" className='md:text-[14px] lg:text-[12px]'>Privacy Policy</Link>
                    </div>
                </div> */}
                {/* Dashboard */}
            </div>
            {/* Mobile */}
            <div className='fixed md:hidden flex gap-5 bottom-0 left-0 bg-[#EAFFF4] shadow w-full px-5 py-3 z-40'>
                <Link to="/tenant/dashboard" className='flex flex-col items-center text-[12px] w-full text-[#6C6C6C] outline-none'>
                    {/* <MdHome className='text-[#6C6C6C]' size="5vh" /> */}
                    <p className='font-medium'>Dashboard</p>
                    <span className={`${pathname === '/tenant/dashboard' ? 'w-14 h-1  bg-[#0FA958]' : 'hidden'}`}></span>
                </Link>
                <Link to="/tenant/payment" className='flex flex-col items-center text-[12px] w-full text-[#6C6C6C] outline-none'>
                    {/* <MdPayment size="5vh" /> */}
                    <p className='font-medium'>Payment</p>
                    <span className={`${pathname === '/tenant/payment' ? 'w-14 h-1  bg-[#0FA958]' : 'hidden'}`}></span>
                </Link>
                <Link to="/tenant/inbox" className='flex flex-col items-center text-[12px] w-full text-[#6C6C6C] outline-none'>
                    {/* <HiMail size="5vh" /> */}
                    <p className='font-medium'>Inbox</p>
                    <span className={`${pathname === '/tenant/inbox' ? 'w-14 h-1  bg-[#0FA958]' : 'hidden'}`}></span>
                </Link>
                <Link to="/tenant/complaints" className='flex flex-col items-center text-[12px] w-full text-[#6C6C6C] outline-none'>
                    {/* <MdPayment size="5vh" /> */}
                    <p className='font-medium'>Complaints</p>
                    <span className={`${pathname === '/tenant/complaints' ? 'w-14 h-1  bg-[#0FA958]' : 'hidden'}`}></span>
                </Link>
            </div>
        </>
    )
}

export default DashboardNav