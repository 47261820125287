import {Checkbox} from '@mui/material'
import React from 'react'
// import {FiEdit2} from 'react-icons/fi'
import DashboardNav from '../../components/DashboardNav'
import Header from '../../components/Header'

const EditProfile = () => {
    return (
        <div className='w-full h-full min-h-screen bg-white flex'>
            <DashboardNav />
            <div className='md:w-10/12 w-full min-h-screen max-h-screen flex flex-col relative overflow-y-auto'>
                <Header />
                <div className='flex flex-col gap-8 p-16'>
                    <div className='flex justify-between text-sm px-14 py-3 border-b border-green-600 font-medium text-[#6C6C6C]'>
                        <h2>My details</h2>
                        <h2>Tenancy details</h2>
                        <h2>Documents</h2>
                    </div>
                    <div className='flex gap-12'>
                        <div className='relative cursor-pointer w-[130px] h-[130px]'>
                            <img src="/profile-avatar.png" className='rounded-full' alt="" />
                            <div className='bg-green-400 rounded-full text-white w-6 h-6 flex items-center justify-center absolute top-20 left-[4.5rem] cursor-pointer'>
                                {/* <FiEdit2 /> */}
                            </div>
                        </div>
                        <form action="" className='w-full flex flex-col gap-14'>
                            <div className='flex flex-wrap gap-10 text-[#6C6C6C] text-sm'>
                                <div className='flex flex-col gap-2 w-[272px] h-12'>
                                    <label className='text-sm text-[#6C6C6C]'>First name</label>
                                    <input type="text" className='p-2 border border-green-600 outline-none focus:outline-none rounded' placeholder='First name' value='Funke' />
                                </div>
                                <div className='flex flex-col gap-2 w-[272px] h-12'>
                                    <label className='text-sm text-[#6C6C6C]'>Last name</label>
                                    <input type="text" className='p-2 border border-green-600 outline-none focus:outline-none rounded' placeholder='First name' value='Orimolade' />
                                </div>
                                <div className='flex flex-col gap-2 w-[272px] h-12'>
                                    <label className='text-sm text-[#6C6C6C]'>Phone number</label>
                                    <input type="text" className='p-2 border border-green-600 outline-none focus:outline-none rounded' placeholder='First name' value='08122222222' />
                                </div>
                                <div className='flex flex-col gap-2 w-[272px] h-12'>
                                    <label className='text-sm text-[#6C6C6C]'>Email</label>
                                    <input type="text" className='p-2 border border-green-600 outline-none focus:outline-none rounded' placeholder='First name' value='funkeoris@gmail.com' />
                                </div>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <label className='text-[13px] text-[#6C6C6C]'>Gender</label>
                                <div className='flex gap-6'>
                                    <div className='flex text-xs text-gray-700 text-left mb-8 items-center'>
                                        <Checkbox style={{padding: '0'}} size='small' />
                                        <span className='ml-1'>Male</span>
                                    </div>
                                    <div className='flex text-xs text-gray-700 text-left mb-8 items-center'>
                                        <Checkbox style={{padding: '0'}} size='small' />
                                        <span className='ml-1'>Female</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='w-full h-[466px] bg-[#F7FEFA] p-5'>
                        <p className='text-sm font-semibold text-[#0FA958]'>Additional Information</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditProfile