import { MenuItem, TextField } from '@mui/material';
import React, { useState } from 'react';
import DashboardNav from '../../components/DashboardNav';
import Header from '../../components/Header';

const Notice = () => {
  const reasons = [
    {
      value: 'Lack of maintenance',
      label: '$',
    },
    {
      value: 'Graduated',
      label: '€',
    },
    {
      value: 'Vilolence',
      label: '฿',
    },
    {
      value: 'Relocating',
      label: '¥',
    },
  ];
  const [currency, setCurrency] = useState('EUR');

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };




  return (
    <div className='w-full h-full min-h-screen bg-white flex'>
      <DashboardNav />
      <div className='md:w-10/12 w-full min-h-screen max-h-screen flex flex-col relative overflow-y-auto'>
        <Header />
        <div className="max-w-2xl mx-auto shadow-lg rounded mt-14 px-4 py-8 md:px-8 lg:px-16 flex flex-col gap-8">
          <div className='flex flex-col gap-3'>
            <h2 className='text-[#0FA958] font-semibold'>Notice of Leaving</h2>
            <p className='text-[#6C6C6C] text-sm'>As part of our policy we require our tenants to give us at least a month notice prior to their desired termination of tenancy. Kindly fill the form below.</p>
          </div>
          <form action="">
            <div className='flex flex-col gap-5'>
              <CustomTextField label="When do you want to leave?" />
              <TextField
                id="outlined-select-currency"
                select
                label="Reason for leaving"
                value={currency}
                onChange={handleChange}
                style={{ width: '100%' }}
              >
                {reasons.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-multiline-static"
                label="Additional Info"
                Additional Info
                rows={4}
                style={{ width: '100%', marginTop: '20px', marginBottom: '10px' }}
              />
            </div>
            <button className='text-white bg-[#0FA958] px-14 py-2 mt-3 text-base rounded transition-all ease-in-out duration-500 hover:bg-[#0c8947]'>Send</button>
          </form>
        </div>
      </div>
    </div>
  )
}
const CustomTextField = (props) => {
  const styles = {
    label: {
      fontSize: 13
    },
    inputP: {
      fontSize: 13,
      marginBottom: 25,
      borderColor: '#0FA958'
    }
  }
  return <>
    <TextField
      variant='standard'
      end
      size='small'
      fullWidth
      InputLabelProps={{ style: styles.label }}
      InputProps={{
        style: styles.inputP
      }}
      {...props}
    />
  </>
}

export default Notice