import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Complaints from './tenants/pages/account/Complaints';
import Dashboard from './tenants/pages/account/Dashboard';
import EditProfile from './tenants/pages/account/EditProfile';
import Inbox from './tenants/pages/account/Inbox';
import Notice from './tenants/pages/account/Notice';
import Payment from './tenants/pages/account/Payment';
import Login from './tenants/pages/auth/login';
import { Signup } from './tenants/pages/auth/signup';

function App() {
  return (
    <div style={{fontFamily: "'Nunito', sans-serif"}}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/tenant/signup' element={<Signup/>} />
          <Route path='/tenant/dashboard' element={<Dashboard/>} />
          <Route path='/tenant/payment' element={<Payment/>} />
          <Route path='/tenant/inbox' element={<Inbox/>} />
          <Route path='/tenant/complaints' element={<Complaints/>} />
          <Route path='/tenant/notice' element={<Notice/>} />
          <Route path='/tenant/edit-profile' element={<EditProfile/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
