import React from 'react';
// import { FiEdit2 } from 'react-icons/fi';
import 'animate.css';
import DashboardNav from '../../components/DashboardNav';
import Header from '../../components/Header';

const Dashboard = () => {
  return (
    <div className='max-w-full mx-auto h-full min-h-screen bg-white flex'>
      <DashboardNav />
      <div className='md:w-10/12 w-full min-h-screen max-h-screen flex flex-col relative overflow-y-auto'>
        <Header />
        <div className="w-full px-5 mt-10">
          <div className='flex h-fit flex-wrap gap-12'>
            {/* Wraped div */}
            <div className='flex flex-col md:flex-row h-auto gap-5'>
              <div className='w-[465px] h-1/2 flex flex-col gap-6'>
                <div className='px-8'>
                  <h1 className='font-semibold text-2xl text-[#0FA958]'>Hello Funke</h1>
                  <p className='text-sm mt-2 text-[#6C6C6C]'>Adedeji Hostel, No 2, Folashade close, UnderG, Ogbomoso.</p>
                </div>
                <div className='w-full bg-[#3F3D56] text-white flex flex-col px-5 py-10 gap-3'>
                  <div className='flex gap-2 items-center'>
                    <img src="/notice.png" alt="" className='w-[18px] h-[18px]' />
                    <h1 className='text-[14px]'>Notice</h1>
                  </div>
                  <p className='text-sm'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque ad fugiat vitae, dolorum voluptatum aliquid. Nam quas nemo praesentium quis similique labore est qui sint, suscipit impedit aut obcaecati vitae?</p>
                </div>
              </div>
              <div className='w-[546px] h-1/2 flex flex-col gap-3'>
                <p className='text-[#0FA958] font-semibold'>Recent Complaints</p>
                <div className='shadow'>
                  <div className='flex justify-between items-center bg-[#EAFFF4] text-[#6C6C6C] px-4 py-3 w-full'>
                    <p className='text-[11px] md:text-base'>Plumbing</p>
                    <p className='text-[11px] md:text-sm'>ID: 19923</p>
                    <p className='text-[11px] md:text-sm font-semibold text-[#FF9900]'>Pending</p>
                  </div>
                  <div className='px-8 py-[16.1px] flex flex-col gap-2'>
                    <h2 className='text-base text-[#6C6C6C] font-medium'>Subject</h2>
                    <p className='text-[#6C6C6C] text-sm'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Velit ratione, enim ad esse ipsa non neque. Provident sunt pariatur dolores dolor esse, asperiores nisi perferendis recusandae aperiam assumenda placeat. Repellat.</p>
                    <p className='text-[#C4C4C4] text-sm'>Created Aug 21, 2022.</p>
                    <button className='text-white bg-[#FF9900] px-6 py-1.5 text-sm w-[178px]'>Send a reminder</button>
                  </div>
                </div>
              </div>
            </div>
            {/* Pending payment */}
            <div className='p-4 lg:px-8 lg:py-8 flex flex-col gap-4 w-full md:px-8 lg:max-w-7xl mx-auto mb-4 lg:mb-0'>
              <h3 className='text-[#0FA958] font-semibold'>Pending Payment</h3>
              <div className='h-auto flex flex-col lg:flex-row gap-8 lg:gap-24 shadow px-8 py-10 rounded-md'>
                <div className='flex flex-col gap-4'>
                  <div className='flex flex-col gap-2'>
                    <div className='flex gap-12 lg:gap-64 font-semibold text-[#6C6C6C] text-sm'>
                      <p className='w-4/12'>Annual Rent</p>
                      <p className='w-28'># 75,000</p>
                    </div>
                    <hr />
                  </div>
                  <div className='flex flex-col gap-2'>
                    <div className='flex gap-12 lg:gap-64 font-semibold text-[#6C6C6C] text-sm'>
                      <p className='w-4/12'>Agent Fee</p>
                      <p className='w-28'># 5,000</p>
                    </div>
                    <hr />
                  </div>
                  <div className='flex flex-col gap-2'>
                    <div className='flex gap-12 lg:gap-64 font-semibold text-[#6C6C6C] text-sm'>
                      <p className='w-4/12'>Security Fee</p>
                      <p className='w-28'># 5,000</p>
                    </div>
                    <hr />
                  </div>
                  <div className='flex flex-col gap-2'>
                    <div className='flex gap-12 lg:gap-64 font-semibold text-[#6C6C6C] text-sm'>
                      <p className='w-4/12'>PHCN Bill</p>
                      <p className='w-28'># 15,000</p>
                    </div>
                    <hr />
                  </div>
                </div>
                <div className='text-[#6C6C6C] flex flex-col gap-1'>
                  <p className='text-sm'>Total</p>
                  <h2 className='font-bold text-xl'># 100,000</h2>
                  <button className='text-white bg-[#0FA958] px-10 py-1.5 text-sm'>Pay now</button>
                  <button className='text-white bg-[#BD2424] px-10 py-1.5 text-sm'>Pay part</button>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full bg-[#0FA958] rounded h-auto text-white mt-10 mb-28 md:mb-6 flex flex-col md:flex-row lg:gap-72 xl:gap-96'>
            <p className='px-10 py-8 lg:text-lg'>Countdown to Next payment</p>
            <div className='flex gap-3'>
              <div className='gap-2 h-full border-r border-gray-300'>
                <div className='p-4 flex flex-col gap-3'>
                  <small>Months</small>
                  <span className='text-3xl font-semibold'>07</span>
                </div>
              </div>
              <div className='gap-2 h-full border-r border-gray-300'>
                <div className='p-4 flex flex-col gap-3'>
                  <small>Weeks</small>
                  <span className='text-3xl font-semibold'>03</span>
                </div>
              </div>
              <div className='gap-2 h-full'>
                <div className='p-4 flex flex-col gap-3'>
                  <small>Days</small>
                  <span className='text-3xl font-semibold'>05</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard