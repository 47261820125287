import React from 'react';
import irrImg from '../../../assets/auth-bg.svg';
import {Checkbox, TextField} from '@mui/material';
import { Link } from 'react-router-dom';


export const Signup = () => {
    const [img, setImg] = React.useState('');
    const getImage = (event) => {
        setImg(event.target.value);
      }
  return (
    <div className='flex h-screen p-2 bg-house-pattern bg-tr-white justify-center bg-no-repeat bg-center bg-cover bg-blend-color-burn items-center'>
    <img draggable="false" className='fixed top-auto' width={450} src={irrImg} alt="bg" />
    <div className='shadow z-10 px-5 pt-10 pb-20 m-auto w-full max-w-xs bg-white text-center'>
      <h3 className='text-3xl font-bold text-austel-green'>Create an account</h3>
      <span className='text-austel-green text-sm block mb-2'>Get started on Austel</span>
      <CustomTextField label="Phone Number" />
      <CustomTextField label="Password" />
          {/* File Upload */}
          <div className="flex relative items-center flex-col mt-2 gap-3 mb-7">
                {/* Upload Text */}
                <div>
                    <h2 className="text-gray-600 text-left">Upload KYT Document</h2>
                    <p className="text-xs text-gray-400 mt-1 text-left">You are required to upload a copy of any of the following -  National ID Card, School ID Card, Voters Card, International Passport, Driver’s License or Admission Letter</p>
                </div>
               <div className="flex gap-10 w-full">
                <label htmlFor="uploadImg">
                    <img src="/fileUpload.png" alt='file upload' className="absolute cursor-pointer w-7 mt-1"/>
                </label>
                <label htmlFor="uploadImg" className="w-10/12">
                     {img ? <div className="text-xs border-b border-green-600 w-full py-1 text-green-600 font-semibold cursor-pointer"> {img}</div> : <div className="text-xs border-b border-green-600 w-full py-1 text-green-600 font-semibold cursor-pointer">Click Here To Upload</div> }
                </label>
               </div>
                <input type="file" id="uploadImg" className="hidden invisible"  placeholder="Click here to upload"  onChange={getImage}/>
            </div>
      <div className='flex text-xs text-gray-700 text-left mb-8 items-center'>
        <Checkbox style={{padding: '0'}} size='small' />
        <span className='ml-1'><Link>Agree to terms and condition</Link></span>
      </div>
      <button className='text-white bg-austel-green py-2 px-20 my-2 block mx-auto text-sm'><Link to="/">Create an account</Link></button>
      <span className="text-xs">Already have an account? <Link className='text-austel-green' to="/">Login</Link></span>
    </div>
  </div>
  )
}


const CustomTextField = (props) => {
    const styles = {
      label: {
        fontSize: 13
      },
      inputP: {
        fontSize: 13,
        marginBottom: 10
      }
    }
    return <>
      <TextField
        variant='standard'
        end
        size='small'
        fullWidth
        InputLabelProps={{style: styles.label}}
        InputProps={{
          style: styles.inputP
        }}
        {...props}
      />
    </>
  }
